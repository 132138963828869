.Popupcss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30.32vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(20px);
  background-color: rgb(220, 220, 220);
  border-radius: 0.83vw;
  z-index: 2;
  padding: 1.66vw;
  border-radius: 0.83vw;
  border: 0.83vw solid rgb(220, 220, 220);
  font-size: 2vh;
  border: 1px solid #ccc; /* Optional border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.forBackgroundDrop {
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
}


.Modal-Popup-css {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30.32vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(20px);
  border-radius: 0.83vw;
  padding: 1.66vw;
  z-index: 2;
  background-color: rgb(220, 220, 220);
}

.fullScreenBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* Adjust the z-index as needed to ensure it overlays other content */
}



.checkboxContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 20vh;
  margin-top: 2vh;
}

.ButtonConatinerOncheckList {
  display: flex;
  justify-content: space-between;
  width: 16.29vw;
  flex-direction: row;
  margin-top: 2.5vh;
}

.checkListheading {
  display: flex;
  flex-direction: column;
  margin-top: .5vh;
}

.informationPopup {
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content vertically */
  flex-direction: column; /* Arranges children in a column */
  padding: 20px; /* Add some padding inside the popup */
  font-size: 2vh;
}


.checkboxItem {
  margin-left: -0.9vw;
}

@media (min-width: 1400px) {
  .checkboxItem {
    margin-left: -0.6vw;
  }
}

@media (min-width: 2000px) {
  .checkboxItem {
    margin-left: -0.4vw;
  }
}

@media (min-width: 3000px) {
  .checkboxItem {
    margin-left: 0;
  }
}