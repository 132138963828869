.coantainer-createFolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 41.66vh;
}

.container-createFolder {
  margin-left: -1.66vw;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.83vw;
  font-size: 2vh;
}



.choseFiles-width {
  margin-right: 0.83vw;
  padding: 0.66vw;
  border: 0.083vw solid #ccc;
  border-radius: 0.41vw;
  font-size: 2vh;
  width: 15.24vw;
  text-transform: none;
  text-transform: none;
}

.checkboxContainercheckbox {
  margin-right: 0.83vw;
  padding: 0.66vw;
  border: 0.083vw solid #ccc;
  border-radius: 0.41vw;
  font-size: 2vh;
  width: 2vw;
}

.buttonforPopup {
  width: 6vw;
  height: 5vh;
  font-size: 2vh;
  background-color: white;
  border: .083 bold gray;
  justify-content: center;
  display: flex;
}

.checkboxContainerlabel {
  border: 0.083vw solid #ccc;
  border-radius: 0.41vw;
  font-size: 2vh;
  width: 20vw;
  height: 6vh;
  display: flex;
  align-items: center
}
.checkboxContainerlabel input[type="checkbox"] {
  border: 0.083vw solid #ccc !important;
  font-size: 2vh;
  width: 3vw;
  height: 3vh;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.input-container input[type="text"] {
  margin-right: 0.83vw;
  padding: 0.66vw;
  border: 0.083vw solid #ccc;
  border-radius: 0.41vw;
  font-size: 2vh;
  width: 17.24vw;
}

.input-container input[type="text"]:focus {
  outline: none;
  border-color: #08645c;
}

.input-container button {
  padding: 0.66vw 1vw;
  background-color: #08645c;
  color: white;
  border: none;
  border-radius: 0.41vw;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #08645c;
}

.closeButton {
  margin-left: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 4vh;
  /* Increase the font size as needed */
  padding: 0.83vw;
  margin-top: -1.66vw;
}

.closeButton:hover {
  color: red;
  /* Change color on hover as an example */
}

.contentArea {
  height: 34.33vh;
  width: 24.59vw;
  display: flex;
  justify-content: space-evenly;
  font-size: 2vh;
  color: black;
  background-color: rgb(220, 220, 220);
}

.contentAreaApproval {
  height: auto;
  width: 24.59vw;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 2vh;
}

.lebel-width {
  width: 8.29vw !important;
}

.css-Button-Classes {
  position: absolute;
  left: 40%;
  height: 5.33vh;
  margin-top: 0.41vw;
  font-size: 2vh;
  width: 7.5vw;
  border: 0.083vw solid;
  padding: 0;
}

.css-Button-Classes-infoPuop {
  height: 5.33vh;
  margin-top: 0.41vw;
  font-size: 2vh !important;
  width: 7.5vw;
  border: 0.083vw solid;
  padding: 0;
}

.css-Button-Classes-infoPuop:hover {
  color: white;
  border: white;
  background-color: #08645c; /* Change background color on hover */
}

/* Active (clicked) effect */
.css-Button-Classes-infoPuop:active {
  background-color: #08645c; /* Darker background color when clicked */
  transform: scale(0.98);    /* Slightly reduce button size on click */
}

 .css-Button-Classes:hover {
  color: white;
  border: white;
  background-color: #08645c; /* Change background color on hover */
}

/* Active (clicked) effect */
 .css-Button-Classes:active {
  background-color: #08645c; /* Darker background color when clicked */
  transform: scale(0.98);    /* Slightly reduce button size on click */
}
.uLListForFolderSelection {
  max-height: 25vh;
  /* Set fixed height */
  width: 20.49vw;
  /* Set fixed width */
  overflow: auto;
}

.containerList {
  margin-top: 1.66vw;
}

/* Add scrollbar */
.uLListForFolderSelection::-webkit-scrollbar {
  width: 0.69vw;
  /* Width of scrollbar */
}

.uLListForFolderSelection::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of scrollbar thumb */
  border-radius: 0.33vw;
  /* Border radius of scrollbar thumb */
}

.uLListForFolderSelection::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Hover color of scrollbar thumb */
}

/* Styling for list items within the container */
.uLListForFolderSelection li {
  padding: 0.41vw;
  /* Add padding to list items */
}

/* Optional: To remove default list styling */
.uLListForFolderSelection {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 2vh;
}


.ListForFolderSelectin {
  height: 4vh;
  /* Increased height for better visibility */
  font-size: 2vh;
  border: 0.083vw solid #ccc;
  /* Add border */
  border-radius: 0.41vw;
  /* Add border-radius */
  padding: 0.41vw 0.83vw;
  /* Add padding */
  background-color: white;
}

.ListForFolderSelectin:hover,
.ListForFolderSelectin:focus {
  background-color: #08645c;
  color: white;
}

.ListForFolderSelectin:focus {
  outline: none;
  /* Remove default focus outline */
}

.directoryWordCss {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-newBranch {
  font-size: 2vh;
  order: 1;
  /* This ensures it appears last */
}

/* Additional styling for selected item */
.ListForFolderSelectin.selected {
  background-color: #08645c;
  /* Change background color */
  color: #333;
  /* Change text color */
  border-color: #333;
  /* Change border color */
}

.ListForFolderSelectin:active {
  background-color: white;
  /* Change background color */
}

.lableTextForCreateBranch {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  margin-bottom: 1.25vw;
  font-weight: 600;
}

.container-uploadFiles {
  width: 24.59vw;
}