/* Ensure you don't use zoom property directly */
html {
  margin: 0;
  overflow: hidden;
  height: calc(100vh / var(--zoom));
  width: calc(100vw / var(--zoom));
  padding: 0;
}

:root {
  --zoom: 1; /* Default value */
}


.App {
  margin: 0;
  overflow: hidden;
  height: calc(100vh / var(--zoom));
  width: calc(100vw / var(--zoom));
  padding: 0;
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
  overflow: hidden;
}

.folderLogo{
  min-width: 1vw;
  padding: 0.16vh;
  margin-top: .15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vh;
  color: #FFD700;
}

.container{
  display: flex;
  flex-direction: row;
}

.excluxivityEnable {
  pointer-events: none;
}
.d-none{
  display: none;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: none; /* Initially hidden */
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.loading-container-Login {
  position: fixed; /* Use fixed to ensure it covers the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex; /* Flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000; /* Ensure it's on top of other content */
}


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.loading-circle {
  width: 1.66vw;
  height: 3.33vh;
  border-radius: 50%;
  background-color: #4CAF50; /* Green color */
  margin: 0 1.41vw;
  animation: loading-animation 1.2s linear infinite;
}

@keyframes loading-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}



.buttonCloseOnPopup{
  margin-top: 5vh !important;
  width: 7.37vw;
  height: 5vh;
}

.LoginScreenBground {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.loginButton {
  position: absolute;
  width: 8.19vw;
  padding: 0.88vw;
  border: none;
  border-radius: 0.41vw;
  box-shadow: 0.16vw 0.16vw 0.41vw rgba(0, 0, 0, 0.3);
  cursor: pointer;
  top: 70%;
  left:46%;
}

.FileContentButton{
  position: absolute;
  width: 8.19vw;
  padding: 0.83vw;
  border: none;
  border-radius: 0.41vw;
  box-shadow: 0.16vw 0.16vw 0.41vw rgba(0, 0, 0, 0.3);
  cursor: pointer;
  left:46%;
  margin-bottom: 0.83vw;
}

.loginButton:focus {
  outline: none; /* Remove default focus outline */
}

.loginButton:active {
  transform: scale(0.95); /* Add a slight scale effect when clicked */
}

