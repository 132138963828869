.leftContainer {
    width: 20vw;
    /* Initial width of left container */
    height:  calc(92.4vh / var(--zoom)) ;
    overflow-x: auto;
    /* Enable horizontal scrolling if needed */
    overflow-y: auto;
    padding: 0;
    box-shadow: 0.16vw 0px 0.16vw rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.02);
    resize: horizontal;
    /* Enable horizontal resizing */
    overflow: auto;
    /* Allow content overflow when resized */
    min-width: 8.19vw;
    /* Minimum width of the container */
}


.drag-pointer {
    min-width: 0.40vw;
    /* Width of the drag pointer */
    cursor: ew-resize;
    /* Horizontal resize cursor */
    background-color: #ccc;
}

.drag-pointer:hover {
    background-color: #aaa;
}


.file {
    cursor: pointer;
    margin-bottom: 0.24vw;
    display: flex;
    align-items: center;
    padding: 0.24vw;
    color: black;
    margin-left: 0.16vw;
    margin-right: 0.16vw;
    border-radius: 0.20vw;
    box-shadow: 0.16vw 0.16vw 0.16vw rgba(0, 0, 0, 0.1);
    width: auto;
    font-size: 2vh;
}

.file>span:first-child {
    margin-left: 0.081vw;
}

.nestedFile {
    width: 16.39vw;
    cursor: pointer;
    margin-bottom: 0.24vw;
    margin-left: 0.81vw;
    border-radius: 0.24vw;
    display: flex;
    justify-content: row;
    font-size: 2vh;
}




.nestedFile:active {
    background-color: #08645c;
    /* Light blue for active */
    color: black;
}

.nestedFile:hover {
    background-color: #08645c;
    /* Light blue for active */
    color: white;
}

.file:hover {
    background-color: #08645c;
    /* Light blue for hover */
    color: white;
}

.file.active {
    background-color: #08645c;
    /* Light blue for active */
    color: white;
    margin-left: 0.16vw;
    margin-right: 0.16vw;
}


.active {
    background-color: #08645c;
    /* Light blue for active */
    color: white;
}

.Directoryword {
    background-color: #08645c;
    /* Light blue for active */
    color: white;
    margin-left: 0.16vw;
    margin-right: 0.16vw;
    height: 5vh;
    border: 0.081vw solid white;
    text-align: center;
    border-radius: 0.20vw;
    font-size: 2vh;
    display: flex;
    justify-content: space-between;
    width: 17.8vw;
}

.folderLogoAndName {
    display: flex;
    justify-content: center;
}

.folderNameLeftSide {
    font-size: 2vh;
    padding: 0.30vw;
    overflow: hidden;
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    text-overflow: ellipsis;
    /* Adds the ellipsis (...) when text overflows */
}



/* For Webkit (Chrome, Safari, Edge) */
.folderNameLeftSide::-webkit-scrollbar {
    width: 0.081vw;
    /* Width of the scrollbar */
}

.folderNameLeftSide::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    /* Track color */
}

.folderNameLeftSide::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    /* Color of the scrollbar thumb */
    border-radius: 0.81vw;
    /* Rounded corners */
    height: 0.083vh;
    /* Height of the scrollbar thumb */
}

/* For IE and Edge */
.folderNameLeftSide {
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge */
}

.folderNameLeftSide::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Webkit (Chrome, Safari) */
}