.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #08645c;
    /* Blue background color */
    height: 7vh;
    /* Full header height in viewport height units */
    box-shadow: 0px 0.16vw 0.16vw rgba(0, 0, 0, 0.3);
    /* Shadow border at the bottom */
}

.left {
    display: flex;
    align-items: center;
    width: calc(63vw / var(--zoom));;
}

.softwareName {
    color: white;
    font-size: 2.50vh;
    font-weight: 600;
}

.right img {
    width: 6vw;
    /* Width in viewport width units */
    height: 6vw;
    /* Height in viewport width units */
    border-radius: 50%;
}

.logoutButton {
    margin-right: 0.83vw;
    z-index: 9999;
    font-size: 2vh;
    border: 0.083vw solid transparent;
    /* Ensure consistent border */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    /* Smooth transition for hover effects */
    width: 5vw;
    height: 3.33vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoutButton:hover {
    cursor: pointer;
    background-color: #08645c;
    color: white;
    border-color: white;
    /* Change border color on hover */
}


.left button {
    width: 5vh;
    height: 5vh;
    font-size: 3.5vh;
    font-weight: bold;
    padding: 0;
    background-color: transparent;
}

.left button:active {
    background-color: #ffffff;
    /* White background on click */
    border-radius: 50%;
    /* Circular shape */
}



.addressBar {
    max-width: 48vw;
    /* Address bar width in viewport width units */
    height: 5vh;
    /* Address bar height in viewport height units */
    font-size: 2.5vw;
    /* Font size in viewport width units */
}

.profilePic {
    border-radius: 4.16vw;
    width: 8.19vw;
    height: 2.77vh;
}

.imageLogo {
    margin-left: 2.08vw;
    height: 7vh;
    width: 13vw;
}


.centered-message {
    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
    height: 80vh;
    /* Full viewport height */
    text-align: center;
    /* Align text center */
}

.centered-message div {
    padding: 1.66vw;
    border: 0.083vw solid #ccc;
    border-radius: 0.41vw;
    background-color: #f0f0f0;
}