/* MainContainer.css */
.mainContainer {
  width: calc(87vw / var(--zoom));
  height: calc(92.5vh / var(--zoom));
  overflow: auto; /* Ensures scrollbars appear when content overflows */
  box-sizing: border-box; /* Ensures padding and borders are included in the element's total width and height */
}

.d-noneArrow{
  display: none !important;
}

.user-profile-icon{
  width: 5.5vw;
}

.approved-text, .rejected-text , .Inprogress-text{
  text-align: center; /* Center text horizontally */
  color: green; /* Adjust color as needed */
  font-size: 2vh; /* Adjust font size */
  font-weight: 700; /* Adjust font weight */
  padding: 0.72vw;
}

.rejected-text {
  color: red; /* Adjust color */
}

.Inprogress-text {
  color: yellowgreen; /* Adjust color */
}

@media (min-width: 400px) {
  .left {
    max-width: calc(48vw / var(--zoom))
  }

  .currentFolderPathCSS {
    max-width: calc(33vw / var(--zoom));
  }

}
@media (min-width: 700px) {
  .display-add-new-program {
    display: flex;
  }
  .display-add-new-program_icon{
    display: none !important;
  }
}
@media (max-width: 700px) {
  .display-add-new-program {
    display: none;
  }
  .display-add-new-program_icon{
    display: flex;
  }
}


@media (min-width: 490px) {
  .left {
    max-width: calc(56vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    max-width: calc(55vw / var(--zoom)) !important;
  }
}

@media (min-width: 1100px) {
  .left {
    max-width: calc(64vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    max-width: calc(58vw / var(--zoom)) !important;
  }
}

@media (min-width: 1200px) {
  .left {
    max-width: calc(66vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    max-width: calc(60vw / var(--zoom)) !important;
  }
}

@media (min-width: 1300px) {
  .left {
    min-width: calc(68vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    min-width: calc(62vw / var(--zoom)) !important;
  }
}

@media (min-width: 1500px) {
  .left {
    min-width: calc(72vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    min-width: calc(64vw / var(--zoom)) !important;
  }
}


@media (min-width: 2000px) {
  .left {
    min-width: calc(76vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    min-width: calc(70vw / var(--zoom)) !important;
  }
}

@media (min-width: 3000px) {
  .left {
    min-width: calc(82vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    min-width: calc(75vw / var(--zoom)) !important;
  }
}
@media (min-width: 4000px) {
  .left {
    min-width: calc(87vw / var(--zoom)) !important;
  }

  .currentFolderPathCSS {
    min-width: calc(82vw / var(--zoom)) !important;
  }
}


@media (min-height: 190px) {
  .leftContainer{
    height: calc(54vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(40.83vh / var(--zoom)) !important;
    width: calc(57.83vw / var(--zoom)) ;
  }
  .fileContentList{
    height: calc(40.83vh / var(--zoom)) !important;
    width: calc(57.83vw / var(--zoom)) ;
  }
}
@media (min-height: 200px) {
  .leftContainer{
    height: calc(64vh / var(--zoom));
  }
  
}

@media (min-height: 240px) {
  .leftContainer{
    height: calc(69vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(61.83vh / var(--zoom)) !important;
  }
}
@media (min-height: 235px) {
  .leftContainer{
    height: calc(65vh  / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(50.83vh / var(--zoom)) !important;
    width: calc(62vw / var(--zoom));
  }
  .fileContentList{
    height: calc(50.83vh / var(--zoom)) !important;
    width: calc(62vw / var(--zoom));
  }
}


@media (min-height: 290px) {
  .leftContainer{
    height: calc(69vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(62vh / var(--zoom)) !important;
    width: calc(67.83vw / var(--zoom));
  }
  .fileContentList{
    height: calc(62vh / var(--zoom)) !important;
    width: calc(67.83vw / var(--zoom));
  }
}

@media (min-height: 300px) {
  .leftContainer {
    height: calc(71vh / var(--zoom)) !important; /* Slightly larger for very small screens */
  }
  .fileContentThumbnail{
    height: calc(66.83vh / var(--zoom)) !important;
    width: calc(70vw / var(--zoom));
  }
  .fileContentList{
    height: calc(66.83vh / var(--zoom)) !important;
    width: calc(70vw / var(--zoom));
  }
}
@media (min-height: 340px) {
  .leftContainer {
    height: calc(73vh / var(--zoom)) !important; /* Slightly larger for very small screens */
  }
}
@media (min-height: 370px) {
  .leftContainer {
    height: calc(73.5vh / var(--zoom)) !important; /* Slightly larger for very small screens */
  }
  .fileContentThumbnail{
    height: calc(70.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(70.83vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    width: auto ;
  }
  .fileContentList{
    width: auto;
  }
}
@media (min-height: 390px) {
  .leftContainer {
    height: calc(77vh / var(--zoom)) !important; /* Slightly larger for very small screens */
  }
}
@media (min-height: 400px) {
  .leftContainer {
    height: calc(77vh / var(--zoom)) !important;
  }
}
@media (min-height: 450px) {
  .leftContainer {
    height: calc(77.5vh / var(--zoom)) !important;
  }
}
@media (min-height: 450px) {
  .leftContainer {
    height: calc(83.5vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(76.83vh / var(--zoom)) !important;
    width: 100%;
  }
  .fileContentList{
    height: calc(73.5vh / var(--zoom)) !important;
    width: 100%;
  }
}
@media (min-height: 500px) {
  .leftContainer {
    height: calc(79vh / var(--zoom)) !important;
  }
}
@media (min-height: 540px) {
  .leftContainer {
    height: calc(83vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(78.83vh / var(--zoom)) !important;
    width: 100%;
  }
  .fileContentList{
    height: calc(78.83vh / var(--zoom)) !important;
    width: 100%;
  }
}
@media (min-height: 578px) {
  .leftContainer {
    height: calc(84vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(78.83vh / var(--zoom)) !important;
    width: 100%
  }
  .fileContentList{
    height: calc(78.83vh / var(--zoom)) !important;
    width: 100%;
  }
}
@media (min-height: 600px) {
  .leftContainer {
    height: calc(92vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(78.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(78.83vh / var(--zoom)) !important;
  }
}
@media (min-height: 650px) {
  .leftContainer {
    height: calc(86vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(80.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(80.83vh / var(--zoom)) !important;
  }
}
@media (min-height: 700px) {
  .leftContainer {
    height: calc(88vh / var(--zoom)) !important;
  }
  .fileContentThumbnail{
    height: calc(82.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(82.83vh / var(--zoom)) !important;
  }
}
@media (min-height: 800px) {
  .leftContainer {
    height: calc(90vh  / var(--zoom)) !important;
  }
    .fileContentThumbnail{
    height: calc(84.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(84.83vh / var(--zoom)) !important;
  }

}
@media (min-height: 900px) {
  .leftContainer {
    height: calc(92vh  / var(--zoom)) !important;
  }
}
@media (min-height: 1000px) {
  .leftContainer {
    height: calc(92vh / var(--zoom)) !important;
  }
  .inputField {
    width: calc(89vw / var(--zoom))
  }
  .fileContentThumbnail{
    height: calc(87.83vh / var(--zoom)) !important;
  }
  .fileContentList{
    height: calc(87.83vh / var(--zoom)) !important;
  }
}
@media (min-height: 1150px) {
  .inputField {
    width: calc(100vw / var(--zoom))
  }
}
@media (min-height: 1200px) {
  .leftContainer {
    height: calc(100vw / var(--zoom)) !important;
  }
  .inputField {
    width: calc(92vw / var(--zoom))
  }

}

@media (min-height: 1400px) {
  .leftContainer {
    height: calc(100vh / var(--zoom)) !important;
  }
}

@media (min-height: 1600px) {
  .leftContainer {
    height: calc(94vh / var(--zoom)) !important;
  }
  .inputField {
    width: calc(95vw / var(--zoom))
  }
}
@media (min-width: 3600px) {
  .inputField {
    width: calc(100vw / var(--zoom))
  }
}
@media (min-width: 4900px) {
  .inputField {
    width: calc(100vw / var(--zoom))
  }
}

.arrow-icon {
  display: inline-block;
  width: 0.99vw;
  /* Adjust width as needed */
  height: 1.66vh;
  /* Adjust height as needed */
  border-right: 1.66vw solid black;
  /* Adjust border thickness and color */
  border-bottom: 0.33vh solid black;
  /* Adjust border thickness and color */
  transform: rotate(45deg);
  /* Rotate the square to form a right arrow */
  margin-right: 0.41vw;
  /* Adjust margin as needed */
}

/* Hide scrollbar for Firefox */

.note {
  font-size: 2vh;
  font-style: italic;
  color: #08645c;
  margin-bottom: 1.66vh;
}

.folderContainerThumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8.83vw;
  height: 15.66vh;
  justify-content: center
}

.folderContainerThumbnail:hover {
  background-color: #08645c;
  /* Light blue for hover */
  color: white;
}

.folderContainerThumbnail.active {
  background-color: #08645c;
  /* Light blue for hover */
  color: white;
}

.marginLeftHeading {
  margin-left: 0.41vw;
}

.folderContainerList {
  display: flex;
  flex-direction: row;
  /* Changed to column */
  align-items: center;
  /* Center align items */
  width: 65vw;
  height: 4.83vh;
  justify-content: start;
  margin-left: 0.83vw;
}

.folderContainerList:hover {
  background-color: #08645c;
  /* Light blue for hover */
  color: white;
}

.folderContainerList.active {
  background-color: #08645c;
  /* Light blue for hover */
  color: white;
}

.folderLogoMiddleThumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.91vw;
  height: 10vh;
  color: #FFD700;
  font-size: 8vh;
}

.folderLogoMiddleList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2vw;
  height: 4.16vh;
  color: #FFD700;
  font-size: 2.5vh;
  margin-left: .16vw;
  margin-right: .16vw;
}

.folderLogoMiddleListHeading {
  width: 2vw;
  height: 4.16vh;
  justify-content: center;
}

.typeCss {
  font-size: 2vh;
  margin-right: 1.66vw;
  width: 15.76vw;
  display: flex;
  align-items: center;
}

.dateAndTime {
  font-size: 2vh;
  width: 12.76vw;
  display: flex;
  align-items: center;
}

.date {
  width: 5.14vw;
  margin-left: 0.51vw;
  display: flex;
  justify-content: start;
}

.time {
  width: 2.40vw;
  /* margin-left: .16vw; */
  display: flex;
  justify-content: end;
}

.ampm {
  width: 1.66vw;
  margin-left: 0vw;
  display: flex;
  justify-content: end;
}

.typeCssHeading {
  font-size: 2vh;
  width: 15.76vw;
  border-right: 0.083vw solid lightgray;
  display: flex;
  align-items: center;
}

.dateAndTimeHeading {
  font-size: 2vh;
  width: 12.76vw;
  border-right: 0.083vw solid lightgray;
  display: flex;
  align-items: center;
}

.folderNameListHeading {
  font-size: 2vh;
  width: 41.54vw;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  height: 4.16vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  margin-left: 0.41vw;
  display: flex;
  align-items: center;
  border-right: 0.083vw solid lightgray;
  margin-left: 1.41vw;
}

.folderName {
  font-size: 2vh;
  max-width: 9vw;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  height: 8.33vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}


.folderNameThumbnail {
  font-size: 2vh;
  max-width: 7.5vw;
  height: 5.5vh;
  word-wrap: break-word;
  overflow: hidden;
  /* Hide overflow content */
  text-overflow: ellipsis;
  /* Add ellipsis (...) when text overflows */
  text-align: center;
  /* Center the text horizontally */
  scrollbar-width: thin;
  /* Thin scrollbar for Firefox */
  scrollbar-color: #888 transparent;
  /* Custom scrollbar color for Firefox */
}


.folderNameList {
  font-size: 2vh;               /* Font size relative to viewport height */
  width: 41.54vw;               /* Container width relative to viewport width */
  overflow: hidden;             /* Hide overflowing content */
  white-space: nowrap;          /* Prevent text from wrapping */
  text-overflow: ellipsis;      /* Display ellipsis (...) for overflow text */
  text-align: center;           /* Center-align text horizontally */
  height: 4.16vh;               /* Container height relative to viewport height */
  display: flex;                /* Use flexbox layout */
  align-items: center;          /* Vertically center text within container */
  /* No scrollbar styling needed */
}



.fileContentThumbnail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  /* height: 79.83vh; */
  height: calc(79.83vh / var(--zoom));
  overflow: auto;
}

.fileContentList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  /* height: 78.83vh; */
  height: calc(78.83vh / var(--zoom));
  overflow: auto;
}

.inputField {
  /* max-width: 81vw; */
  /* width: calc(81vw / var(--zoom)); */
  display: flex;
  flex-direction: row;
  border-bottom: 0.16vw solid #ccc;
  border-radius: 0.41vw;
  padding: 0.41vw;
}

.inputField > *:nth-child(4) {
  margin-left: auto;
}
.buttonFor {
    height: 5vh;
    border: none;
    color: #097969;
    margin-right: 0.83vw;
    font-size: 3vh;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    border-radius: 0.25vw;
    width: 1.96vw;
    justify-content: center;
    margin-top: 1vh !important;
}

.create-new-folder {
  width: 3.68vw;
}

.upload-btn-icon {
  margin-left: 0.5vw;
}

/* .buttonFor:hover {
  background-color: #097969;
  height: 5vh;
  border: none;
  color: white;
} */

.buttonFor:hover .icon {
  border-bottom: 0.11vw solid white;
}

.divForUploadButton {
  display: flex;
  width: 10.96vw;
  margin-right: 0.83vw;
  justify-content: right;
}

.refreshButton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Add hover effect */
.refreshButton:hover {
  background-color: #08645c;
  color: white;
}

#currentFolderpath.folderPathInfoHolder {
  font-size: 2vh;
  /* Adjust font size as needed */
  font-weight: bold;
  /* Make the text bold */
  margin-bottom: 0.83vw;
  /* Add some space below the element */
}

#currentFolderpath.folderPathInfoHolder::before {
  content: "\00BB";
  /* Unicode character for right-pointing double angle quotation mark (») */
  margin-right: 0.41vw;
  /* Add space between the angle quotation mark and the text */
}



.download-btn {
  position: relative;
}

.icon {
  border-bottom: .11vw solid #097969;
  display: inline-block;
  font-size: 5vh;
  height: 5.33vh;
  position: relative;
  width: .98vw;
  width: 1.2vw;
}


.download-btn:hover::before {
  content: "Download Software";
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
}

.pageRefresh {
  position: relative;
}

.pageRefresh:hover::before {
  content: "Refresh Current Page";
  position: absolute;
  top: 100%;
  left: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
}

.goUpword {
  position: relative;
}

.goUpword:hover::before {
  content: "One Level Up";
  position: absolute;
  top: 100%;
  left: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
  z-index: 999;
}

.Upload-btn {
  position: relative;
}

.Upload-btn:hover::before {
  content: "Upload Software";
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
  z-index: 999;
}

.ApprovebuttonCss {
  position: relative;
}

.ApprovebuttonCss:hover::before {
  content: "Approve";
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
}

.RejectbuttonCss {
  position: relative;
}

.RejectbuttonCss:hover::before {
  content: "Reject";
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
}

.CreateFolder-btn {
  position: relative;
}

.CreateFolder-btn:hover::before {
  content: "Create New Folder";
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
}

.create-newBranch {
  position: relative;
}

.create-newBranch:hover::before {
  position: absolute;
  top: 100%;
  right: calc(100% + 0px);
  /* Adjust this value as needed */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  padding: 0.41vw 0.83vw;
  border-radius: 0.41vw;
  font-size: 2vh;
  white-space: nowrap;
  transform: translateY(-50%);
  border: 0.083vw solid black;
}

.DisplayItems {
  display: flex;
  flex-direction: row;
}

.singelFileDownloadButton {
  font-size: 2vh;
  width: 7.37vw;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  height: 3.33vh;
  z-index: 10;
  border-radius: 0.41vw;
  /* Add border radius */
  background-color: #08645c;
  /* Set background color to green */
  color: white;
  /* Set text color to black */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s, color 0.3s;
  /* Smooth transition */
}

.singelFileDownloadButton:hover {
  background-color: white;
  /* Change background color to white on hover */
  color: black;
  /* Change text color to black on hover */
}


.currentFolderPathCSS {
  width: calc(57vw / var(--zoom));
  /* Set the fixed width */
  overflow-x: auto;
  /* Enable horizontal scrolling if content overflows */
  white-space: nowrap;
  font-size: 2vh;
  /* Prevent text from wrapping */
  border-radius: 0.20vw;
  border: 1px solid #ddd;
  box-shadow: 0.083vw 0.083vw 0.083vw rgba(0, 0, 0, 0.1);
  height: 4vh;
  display: flex;
  align-items: center;
}

.marginForactualName{
  margin-left: 2vh;
}

.currentFolderPathCSS::-webkit-scrollbar {
  height: 0.33vh;
  /* Set the height of the scrollbar */
}

.currentFolderPathCSS::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
}

/* Basic styling for the ListView container */
.list-view {
  position: fixed;
  bottom: 0px;
  /* Adjust as needed */
  right: 0px;
  /* Adjust as needed */
  background-color: #f0f0f0;
  border: 0.083vw solid #ccc;
  box-shadow: 0 0 0.83vw rgba(0, 0, 0, 0.1);
  max-width: calc(81.65vw / var(--zoom));
  height: 4vh;
  display: flex;
  flex-direction: row-reverse;
}

/* Styling for the buttons */
.view-toggle button {
  cursor: pointer;
  border: 0.083vw solid #ccc;
  border-radius: 0.25vw;
  background-color: #fff;
  font-size: 2vh;
  border: 0.083vw solid #097969;
  width: 3vw;
}

.list-view-btn {
    display: flex;
    width: 1.9vw;
    border: 0.033vh solid gray;
}
.classNewFolderCreate{
  margin-left: 9vw;
  margin-top: 4vh;
}
.ContainerViewForfiletype {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 81.96vw;
  border-bottom: 0.083vw solid #ccc;
}

.arrowMarkClick {
  width: 0.9vw;
  z-index: 100;
  pointer-events: auto;
  font-size: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: rotate(90deg); */
  font-weight: 600;
}

.classForListTimeStampList {
  display: flex;
  width: 65vw;
}

.list-view-btn-root {
  height: 3.16vh;
  width: 1.5vw;
  position: relative;
}



.list-view-btn-root::before {
  content: "List View";
  position: absolute;
  top: calc(-100% - 0px);
  left: calc(-100% - 1vw);
  /* Adjust as needed to position it to the left */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  font-size: 2vh;
  white-space: nowrap;
  padding: .25vw 0.5vw;
  /* Adjust padding as needed */
  border-radius: 0.16vw;
  opacity: 0;
  /* Initially hide the element */
  transition: opacity 0.3s ease;
  /* Smooth transition for opacity */
  z-index: 999;
  pointer-events: none;
}

.list-view-btn-root:hover::before {
  opacity: 1;
  /* Show the element on hover */
}


.view-toggle {
  display: flex;
}

.thumbnail-View-btn {
    height: 3.16vh;
    width: 1.52vw;
    position: relative;
}



.thumbnail-View-btn::before {
  content: "Thumbnail View";
  position: absolute;
  top: calc(-100% - 0px);
  left: calc(-100% - 5vw);
  /* Adjust as needed to position it to the left */
  background-color: #08645c;
  /* Semi-transparent black background */
  color: #fff;
  /* Text color */
  font-size: 2vh;
  white-space: nowrap;
  padding: 0.25vw 0.50vw;
  /* Adjust padding as needed */
  border-radius: 0.33vh;
  opacity: 0;
  /* Initially hide the element */
  transition: opacity 0.3s ease;
  /* Smooth transition for opacity */
  pointer-events: none;
}

.thumbnail-View-btn:hover::before {
  opacity: 1;
  /* Show the element on hover */
}


.thumbnail-View-btn:hover .thumbnail-view-btn-styles {
  border-bottom: 0.083vw solid white;
}

.thumbnail-View-btn:hover .thumbnail-view-btn-rectangel {
  border: 0.083vw solid white;
}

.thumbnail-view-btn-rectangel {
  height: 1.7vh;
  width: 1vw;
  border: 0.083vw solid #097969;
  margin-left: 0.16vw;
}

.thumbnail-view-btn-styles {
  border-bottom: 0.083vw solid #097969;
  width: 1.1vw;
  margin-left: 0.16vw;
}


.icon-outlined,
.icon-filled {
  position: absolute;
  transition: opacity 0.3s;
  width: 1.96vw;
  height: 4vh;
  font-size: 4vh !important;
}

.list-viewButton-css {
  width: 1.96vw;
  height: 4vh;
  font-size: 4vh !important;
  color: #08645c;
  position: fixed;
}

.icon-filled {
  opacity: 0;
}

.buttonFor:hover .icon-filled {
  opacity: 1;
}

.buttonFor:hover .icon-outlined {
  opacity: 0;
}